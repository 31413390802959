"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

function _interopDefault(e) {
  return e && "object" == _typeof(e) && "default" in e ? e.default : e;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var React = _interopDefault(require("react"));

function AppContainer(e) {
  return React.Children.only(e.children);
}

var hot_prod = function hot_prod() {
  return function (e) {
    return e;
  };
},
    areComponentsEqual = function areComponentsEqual(e, n) {
  return e === n;
},
    setConfig = function setConfig() {},
    cold = function cold(e) {
  return e;
},
    configureComponent = function configureComponent() {};

exports.AppContainer = AppContainer, exports.hot = hot_prod, exports.areComponentsEqual = areComponentsEqual, exports.setConfig = setConfig, exports.cold = cold, exports.configureComponent = configureComponent;